<template>
  <a-modal :visible="visible" :title="`${userInfo?.name}`" @ok="handleOk" @cancel="cancel">
    <a-transfer
      :data-source="allList"
      :titles="['不可查看', '可查看']"
      :target-keys="oriTargetKeys"
      :selected-keys="selectedKeys"
      :render="item => item.title"
      @change="handleChange"
      @selectChange="handleSelectChange"
      showSearch
      :locale="{ itemUnit: '项', itemsUnit: '项', notFoundContent: '列表为空', searchPlaceholder: '输入店铺名' }	"
    />
  </a-modal>
</template>

<script>
import { ref, watch } from "vue";
import { getShopId, setShopId } from "@/utils/api";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    userInfo: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, context) {
    const visible = ref(props.show);
    const allList = ref([]);
    const selectedKeys = ref([]);
    const oriTargetKeys = ref([]);
    //
    function handleOk() {
      context.emit("update:show", false);
    }
    //
    function cancel() {
      context.emit("update:show", false);
    }
    //
    function handleData(arr) {
      arr.forEach(item => {
        item.key = item.shopId;
        item.title = item.shopName;
        item.description = item.shopName;
      });
    }
    //
    async function loadData() {
      console.log(props.userInfo);
      const res = await getShopId(props.userInfo.userId);
      if (res.code == 0) {
        const { notOwnedShops, ownedShops } = res.data;
        allList.value = [...notOwnedShops, ...ownedShops];
        oriTargetKeys.value = ownedShops.map(item => item.shopId);
        handleData(allList.value);
        console.log(ownedShops, oriTargetKeys);
      }
    }
    function handleSelectChange(e, v) {
      selectedKeys.value = [...e, ...v];
    }
    //
    async function handleChange(e, v, m) {
      console.log(e, v, m);
      const res = await setShopId({
        shopIds: e,
        userId: props.userInfo.userId
      });
      if (res.code == 0) {
        loadData();
      }
      console.log(res);
    }
    //
    watch(visible, val => {
      context.emit("update:show", val);
    });
    watch(
      props,
      val => {
        const show = val.show;
        if (show) {
          loadData();
        } else {
          setTimeout(() => {
            allList.value = [];
            oriTargetKeys.value = [];
            selectedKeys.value = [];
          }, 200);
        }
        visible.value = show;
      },
      { deep: true }
    );

    return {
      visible,
      handleOk,
      cancel,
      allList,
      selectedKeys,
      handleChange,
      handleSelectChange,
      oriTargetKeys
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep .ant-transfer-list{
    height: 350px;
}
</style>