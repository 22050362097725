import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "role_manage" }
const _hoisted_2 = { class: "role_manage_add" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_m_dialog_content = _resolveComponent("m-dialog-content")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_PlusCircleOutlined = _resolveComponent("PlusCircleOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_m_table = _resolveComponent("m-table")!
  const _component_setShopIdModal = _resolveComponent("setShopIdModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_modal, {
      title: _ctx.title,
      "confirm-loading": _ctx.confirmLoading,
      onOk: $setup.addNew,
      visible: _ctx.showDialog,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_m_dialog_content, {
          rules: _ctx.rules,
          forms: _ctx.forms,
          formItems: $setup.trueDialogForm
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", null, [
              _cache[7] || (_cache[7] = _createElementVNode("p", null, "选择用户角色：", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myRoles, (checkbox) => {
                return (_openBlock(), _createBlock(_component_a_checkbox, {
                  checked: checkbox.isSelected,
                  "onUpdate:checked": ($event: any) => ((checkbox.isSelected) = $event),
                  key: checkbox.roleId
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(checkbox.roleName), 1)
                  ]),
                  _: 2
                }, 1032, ["checked", "onUpdate:checked"]))
              }), 128))
            ], 512), [
              [_vShow, _ctx.dialogType != 'resetPassword']
            ])
          ]),
          _: 1
        }, 8, ["rules", "forms", "formItems"])
      ]),
      _: 1
    }, 8, ["title", "confirm-loading", "onOk", "visible"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_button, {
        type: "primary",
        onClick: $setup.add
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_PlusCircleOutlined)
        ]),
        default: _withCtx(() => [
          _cache[8] || (_cache[8] = _createTextVNode(" 添加新用户 "))
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_m_table, {
      buttons: _ctx.buttons,
      columns: _ctx.columns,
      loading: _ctx.loading,
      totals: _ctx.total,
      currentPage: _ctx.currentPage,
      onPageChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.pageChange($event))),
      onDelete: _cache[2] || (_cache[2] = ($event: any) => ($setup.deleteRoles($event))),
      onEdit: _cache[3] || (_cache[3] = ($event: any) => ($setup.handleEvent('edit', $event))),
      onSetShopId: _cache[4] || (_cache[4] = ($event: any) => ($setup.setShopId($event))),
      onResetPassword: _cache[5] || (_cache[5] = ($event: any) => ($setup.handleEvent('resetPassword', $event))),
      data: _ctx.tableList
    }, null, 8, ["buttons", "columns", "loading", "totals", "currentPage", "data"]),
    _createVNode(_component_setShopIdModal, {
      show: _ctx.setShopIdData.show,
      "onUpdate:show": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.setShopIdData.show) = $event)),
      userInfo: _ctx.setShopIdData.data
    }, null, 8, ["show", "userInfo"])
  ]))
}